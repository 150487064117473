<template>
    <div :class="['loading_wrap', styleClass]" v-show="isShow">
        <div class="loading_content">
            <div class="loading">
                <div class="line1"></div>
                <div class="line2"></div>
                <div class="line3"></div>
                <div class="line4"></div>
                <div class="line5"></div>
                <div class="line6"></div>
                <div class="line7"></div>
                <div class="line8"></div>
                <div class="line9"></div>
                <div class="line10"></div>
                <div class="line11"></div>
                <div class="line12"></div>
            </div>
        </div>
    </div>
</template>

<script>
import '../../assets/js/px2rem'
export default {
    name: "LinLoading",
    data() {
        return {
            styleClass: '',
            isShow: false
        }
    },
    methods: {
        open() {
            this.isShow = true;
        },
        close() {
            this.styleClass = 'fade_out';
            setTimeout(() => {
                this.isShow = false;
                this.styleClass = '';
            }, 300)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/reset.css';
@import '../../assets/css/animate.css';
.loading_wrap{
    position: fixed;
    top: 0;
    left: 0;
    bottom:0 ;
    right: 0;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    .loading_content{
        width: 1rem;
        height: 0.9rem;
        background-color: rgba(0, 0, 0, .6);
        border-radius: 6px;
        .loading{
            display: inline-block;
            width: 100%;
            height: 100%;
            zoom: 0.4; /*通过修改这个值(值的区间为0-1)的大小来设置菊花的大小*/
            div {
                position: absolute;
                left: 49.8%;
                top: 49.5%;
                width: 3px;
                height: 16px;
                background: #fff;
                opacity: 1;
                animation: loading 0.84s linear infinite;
                -webkit-animation: loading 0.84s linear infinite;
                -moz-animation: loading 0.84s linear infinite;
                -ms-animation: loading 0.84s linear infinite;
                -o-animation: loading 0.84s linear infinite;
            }
            .line1{
                height: 19px;
                -webkit-transform: rotate(0deg) translate(0, -34px);
                -moz-transform: rotate(0deg) translate(0, -34px);
                -ms-transform: rotate(0deg) translate(0, -34px);
                -o-transform: rotate(0deg) translate(0, -34px);
                -webkit-animation-delay: 0s;
                -moz-animation-delay: 0s;
                -ms-animation-delay: 0s;
                -o-animation-delay: 0s;
            }
            .line2{
                -webkit-transform:rotate(30deg) translate(0, -34px);
                -moz-transform:rotate(30deg) translate(0, -34px);
                -ms-transform:rotate(30deg) translate(0, -34px);
                -o-transform:rotate(30deg) translate(0, -34px);
                -webkit-animation-delay: 0.07s;
                -moz-animation-delay: 0.07s;
                -ms-animation-delay: 0.07s;
                -o-animation-delay: 0.07s;
            }
            .line3{
                -webkit-transform:rotate(60deg) translate(0, -34px);
                -moz-transform:rotate(60deg) translate(0, -34px);
                -ms-transform:rotate(60deg) translate(0, -34px);
                -o-transform:rotate(60deg) translate(0, -34px);
                -webkit-animation-delay: 0.14s;
                -moz-animation-delay: 0.14s;
                -ms-animation-delay: 0.14s;
                -o-animation-delay: 0.14s;
            }
            .line4{
                -webkit-transform:rotate(90deg) translate(0, -34px);
                -moz-transform:rotate(90deg) translate(0, -34px);
                -ms-transform:rotate(90deg) translate(0, -34px);
                -o-transform:rotate(90deg) translate(0, -34px);
                -webkit-animation-delay: 0.21s;
                -moz-animation-delay: 0.21s;
                -ms-animation-delay: 0.21s;
                -o-animation-delay: 0.21s;
            }
            .line5{
                -webkit-transform:rotate(120deg) translate(0, -34px);
                -moz-transform:rotate(120deg) translate(0, -34px);
                -ms-transform:rotate(120deg) translate(0, -34px);
                -o-transform:rotate(120deg) translate(0, -34px);
                -webkit-animation-delay: 0.28s;
                -moz-animation-delay: 0.28s;
                -ms-animation-delay: 0.28s;
                -o-animation-delay: 0.28s;
            }
            .line6{
                -webkit-transform:rotate(150deg) translate(0, -34px);
                -moz-transform:rotate(150deg) translate(0, -34px);
                -ms-transform:rotate(150deg) translate(0, -34px);
                -o-transform:rotate(150deg) translate(0, -34px);
                -webkit-animation-delay: 0.35s;
                -moz-animation-delay: 0.35s;
                -ms-animation-delay: 0.35s;
                -o-animation-delay: 0.35s;
            }
            .line7{
                -webkit-transform:rotate(180deg) translate(0, -34px);
                -moz-transform:rotate(180deg) translate(0, -34px);
                -ms-transform:rotate(180deg) translate(0, -34px);
                -o-transform:rotate(180deg) translate(0, -34px);
                -webkit-animation-delay: 0.42s;
                -moz-animation-delay: 0.42s;
                -ms-animation-delay: 0.42s;
                -o-animation-delay: 0.42s;
            }
            .line8{
                -webkit-transform:rotate(210deg) translate(0, -34px);
                -moz-transform:rotate(210deg) translate(0, -34px);
                -ms-transform:rotate(210deg) translate(0, -34px);
                -o-transform:rotate(210deg) translate(0, -34px);
                -webkit-animation-delay: 0.49s;
                -moz-animation-delay: 0.49s;
                -ms-animation-delay: 0.49s;
                -o-animation-delay: 0.49s;
            }
            .line9{
                -webkit-transform:rotate(240deg) translate(0, -34px);
                -moz-transform:rotate(240deg) translate(0, -34px);
                -ms-transform:rotate(240deg) translate(0, -34px);
                -o-transform:rotate(240deg) translate(0, -34px);
                -webkit-animation-delay: 0.56s;
                -moz-animation-delay: 0.56s;
                -ms-animation-delay: 0.56s;
                -o-animation-delay: 0.56s;
            }
            .line10{
                -webkit-transform:rotate(270deg) translate(0, -34px);
                -moz-transform:rotate(270deg) translate(0, -34px);
                -ms-transform:rotate(270deg) translate(0, -34px);
                -o-transform:rotate(270deg) translate(0, -34px);
                -webkit-animation-delay: 0.63s;
                -moz-animation-delay: 0.63s;
                -ms-animation-delay: 0.63s;
                -o-animation-delay: 0.63s;
            }
            .line11{
                -webkit-transform:rotate(300deg) translate(0, -34px);
                -moz-transform:rotate(300deg) translate(0, -34px);
                -ms-transform:rotate(300deg) translate(0, -34px);
                -o-transform:rotate(300deg) translate(0, -34px);
                -webkit-animation-delay: 0.7s;
                -moz-animation-delay: 0.7s;
                -ms-animation-delay: 0.7s;
                -o-animation-delay: 0.7s;
            }
            .line12{
                -webkit-transform:rotate(330deg) translate(0, -34px);
                -moz-transform:rotate(330deg) translate(0, -34px);
                -ms-transform:rotate(330deg) translate(0, -34px);
                -o-transform:rotate(330deg) translate(0, -34px);
                -webkit-animation-delay: 0.77s;
                -moz-animation-delay: 0.77s;
                -ms-animation-delay: 0.77s;
                -o-animation-delay: 0.77s;
            }
        }
    }
}
.fade_out{
    animation: fadeOut 0.3s forwards ease;
    -webkit-animation: fadeOut 0.3s forwards ease;
    -moz-animation: fadeOut 0.3s forwards ease;
    -o-animation: fadeOut 0.3s forwards ease;
    -ms-animation: fadeOut 0.3s forwards ease;
}

@keyframes loading {
    from {
        opacity: 1;
        width: 5px;
        height: 20px;
    }
    to {
        opacity: 0.25;
        width: 5px;
        height: 20px;
    }
}

@-webkit-keyframes loading {
    from {
        opacity: 1;
        width: 5px;
        height: 20px;
    }
    to {
        opacity: 0.25;
        width: 5px;
        height: 20px;
    }
}
@-moz-keyframes loading {
    from {
        opacity: 1;
        width: 5px;
        height: 20px;
    }
    to {
        opacity: 0.25;
        width: 5px;
        height: 20px;
    }
}
@-o-keyframes loading {
    from {
        opacity: 1;
        width: 5px;
        height: 20px;
    }
    to {
        opacity: 0.25;
        width: 5px;
        height: 20px;
    }
}
@-ms-keyframes loading {
    from {
        opacity: 1;
        width: 5px;
        height: 20px;
    }
    to {
        opacity: 0.25;
        width: 5px;
        height: 20px;
    }
}
</style>
