import Vue from 'vue'
import App from './App.vue'
import router from '../router'
import store from '../store'

import "./assets/js/px2rem"
import './assets/css/reset.css'
import './assets/css/animate.css'
import './assets/js/eventbus'

import LinAlert from './components/LinAlert/main'
import linLoading from './components/LinLoading/main'

Vue.config.productionTip = false;

Vue.use(LinAlert);
Vue.use(linLoading);

const vue = new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app');

export default vue
