const state = {
    pageName: ''
}

const mutations = {
    SAVE_PAGE_NAME: (state, name) => {
        state.pageName = name
    }
}

const actions = {
    updatePageName({ commit }, name) {
        commit('SAVE_PAGE_NAME', name)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
