<template>
    <div class="content">
        <div class="header">
          <div class="logo">
            <img src="../assets/images/logo.png" alt="">
          </div>
          <div class="menu">
            <div v-for="(item, i) in menus" :key="i" class="item" @click="handleClick(i, item.path)">
              <img :src='currentIndex === i ? item.active : item.cancel' alt="">
              <p :style="{color: currentIndex === i ? '#0097FF' : '#000'}">{{ item.title }}</p>
            </div>
          </div>
        </div>
        <div class="content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
  export default {
      name: "Layout",
      data() {
        return {
          menus: [
            {
              cancel: require('../assets/images/home_cancel.png'),
              active: require('../assets/images/home_active.png'),
              title: '首页',
                path: '/'
            },
            {
              cancel: require('../assets/images/product_cancel.png'),
              active: require('../assets/images/product_active.png'),
              title: '产品中心',
                path: '/product'
            },
            {
              cancel: require('../assets/images/news_cancel.png'),
              active: require('../assets/images/news_active.png'),
              title: '公司新闻',
                path: '/news'
            },
            {
              cancel: require('../assets/images/contact_cancel.png'),
              active: require('../assets/images/contact_active.png'),
              title: '联络我们',
                path: '/contact'
            }
          ],
          currentIndex: 0
        }
      },
      mounted() {
          this.resetState();
          this.$EventBus.$on('reset', () => {
              this.resetState();
          })
      },
      methods: {
          resetState() {
              const path = this.$route.path;
              console.log('path', path)
              this.menus.map((item, i) => {
                  if(item.path === path) {
                      this.currentIndex = i
                  }
              });
          },
          handleClick(index, path) {
              this.currentIndex = index;
              this.$router.push(path)
          }
      }
  }
</script>

<style lang="scss" scoped>
.content{
  width: 100%;
    .header{
        display: flex;
        align-items: center;
        width: 100%;
        padding: 5px 20px;
        box-sizing: border-box;
        .logo{
            width: 142px;
            height: 70px;
            margin-right: 100px;
            img{
                width: 100%;
            }
        }
        .menu{
            display: flex;
            .item{
                display: flex;
                align-items: center;
                margin-right: 30px;
                cursor: pointer;
                img{
                    width: 36px;
                    height: 36px;
                    flex-shrink: 0
                }
                p{
                    padding-left: 5px;
                    font-size: 14px;
                }
            }
        }
    }
}
</style>
