<template>
    <div class="alert_wrap" v-show="isShow">
        <div :class="styleClass">
            <span class="icon"></span>
            <span class="msg">{{ message }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "LinAlert",
    props: {
        message: {
            type: String,
            default: 'Error'
        },
        during: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            styleClass: 'alert_in',
            isShow: false
        }
    },
    methods: {
        open() {
            this.isShow = true;
        },
        close() {
            this.styleClass = 'alert_out';
            setTimeout(() => {
                this.isShow = false;
                this.styleClass = 'alert_in';
            }, 300)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/animate.css';
.alert_wrap{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    .alert_in{
        min-width: 0.6rem;
        padding: 0.13rem 0.15rem;
        text-align: center;
        background-color: rgba(0, 0, 0, .6);
        border-radius: 4px;
        opacity: 0;
        transform-origin: center center;
        transform: scale(1.5, 1.5);
        animation: scaleFadeIn 0.2s forwards ease;
        -webkit-animation: scaleFadeIn 0.2s forwards ease;
        -moz-animation: scaleFadeIn 0.2s forwards ease;
        -o-animation: scaleFadeIn 0.2s forwards ease;
        -ms-animation: scaleFadeIn 0.2s forwards ease;
        span{
            font-size: 14px;
            color: #fff;
            letter-spacing: 1px;
        }
    }
    .alert_out{
        min-width: 0.6rem;
        padding: 0.13rem 0.15rem;
        text-align: center;
        background-color: rgba(0, 0, 0, .6);
        border-radius: 4px;
        opacity: 1;
        transform-origin: center center;
        transform: scale(1, 1);
        animation: fadeOut 0.3s forwards ease;
        -webkit-animation: fadeOut 0.3s forwards ease;
        -moz-animation: fadeOut 0.3s forwards ease;
        -o-animation: fadeOut 0.3s forwards ease;
        -ms-animation: fadeOut 0.3s forwards ease;
        span{
            font-size: 14px;
            color: #fff;
            letter-spacing: 1px;
        }
    }
}
@media screen and (max-width: 450px) {
    .alert_wrap{
        .alert_in{
            span{
                font-size: 0.14rem;
            }
        }
        .alert_out{
            span{
                font-size: 0.14rem;
            }
        }
    }
}
</style>
