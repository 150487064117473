import LinAlert from './main.vue';

const linAlert = {};

linAlert.install = function(Vue) {
    const alertInstance = Vue.extend(LinAlert);
    let currAlert;
    const initInstance = () => {
        currAlert = new alertInstance();
        let alertBox = currAlert.$mount().$el;
        document.body.appendChild(alertBox);
    };
    Vue.prototype.$alert = {
        show(options) {
            if (!currAlert) {
                initInstance();
            }
            if (typeof options === 'string') {
                currAlert.message = options;
            } else if (typeof options === 'object') {
                Object.assign(currAlert, options);
            }
            currAlert.open();
            setTimeout(() => {
                currAlert.close()
            }, currAlert.during * 1000);
            return currAlert;
        }
    };
};
export default linAlert;
