import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../src/components/Layout';
Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            component: Layout,
            children: [
                {
                    path: '/',
                    name: 'Home',
                    component: () => import('../src/view/Home'),
                },
                {
                    path: '/product',
                    name: 'Product',
                    component: () => import('../src/view/Product'),
                },
                {
                    path: '/news',
                    name: 'News',
                    component: () => import('../src/view/News'),
                },
                {
                    path: '/contact',
                    name: 'Contact',
                    component: () => import('../src/view/Contact'),
                },
                {
                    path: '/news_01',
                    name: 'Detail01',
                    component: () => import('../src/view/Detail01'),
                },
                {
                    path: '/news_02',
                    name: 'Detail02',
                    component: () => import('../src/view/Detail02'),
                }
            ],
        }
    ]
});

export default router
