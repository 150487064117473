import LinLoading from './main.vue';

const linLoading = {};

linLoading.install = function(Vue) {
    const alertInstance = Vue.extend(LinLoading);
    let currLoading;
    const initInstance = () => {
        currLoading = new alertInstance();
        let loadingBox = currLoading.$mount().$el;
        document.body.appendChild(loadingBox);
    };
    Vue.prototype.$loading = {
        show() {
            if (!currLoading) {
                initInstance();
            }
            currLoading.open();
        },
        close() {
            currLoading.close();
        }
    };
};
export default linLoading;
